import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'cue-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ModalComponent {
  // modal = inject(DialogRef);
  @Input() modal;
  //eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  onClose() {
    if (this.modal) {
      this.modal.close(0);
    } else {
      this.close.emit(null);
    }
  }
}
