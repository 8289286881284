import { APP_INITIALIZER, isDevMode, makeEnvironmentProviders, Provider } from '@angular/core';
import {
  GtagFn,
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
  provideGoogleAnalyticsRouter,
} from '@hakimio/ngx-google-analytics';
import { DOCUMENT } from '@angular/common';

export const NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: GoogleAnalyticsInitializer,
  deps: [NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN, DOCUMENT],
};

export function GoogleAnalyticsInitializer(settings: IGoogleAnalyticsSettings, gtag: GtagFn, document: Document) {
  return async () => {
    if (!settings.ga4TagId) {
      console.info('Empty tracking code for Google Analytics. Google analytics disabled.');
      return;
    }

    if (!gtag) {
      if (!isDevMode()) {
        console.error("Couldn't create or read gtag() fn. Make sure this module is running on a Browser w/ access to Window interface.");
      }

      return;
    }

    if (!document) {
      if (!isDevMode()) {
        console.error(
          "Couldn't to access Document interface. Make sure this module is running on a Browser w/ access to Document interface.",
        );
      }
    }

    // Set default ga.js uri
    settings.uri = settings.uri || `https://www.googletagmanager.com/gtag/js?id=${settings.ga4TagId}`;

    // these commands should run first!
    settings.initCommands = settings?.initCommands ?? [];

    // assert config command
    if (!settings.initCommands.find((x) => x.command === 'config')) {
      settings.initCommands.unshift({ command: 'config', values: [settings.ga4TagId] });
    }

    // assert js command
    if (!settings.initCommands.find((x) => x.command === 'js')) {
      settings.initCommands.unshift({ command: 'js', values: [new Date()] });
    }

    for (const command of settings.initCommands) {
      gtag(command.command, ...command.values);
    }

    const s: HTMLScriptElement = document.createElement('script');
    s.async = true;
    s.src = settings.uri;

    if (settings.nonce) {
      s.setAttribute('nonce', settings.nonce);
    }

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  };
}
export function provideGoogleAnalytics(options: { keyFactory: () => string | undefined }) {
  const providers = [
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: () => {
        const key = options.keyFactory();
        return {
          ga4TagId: key,
        } as IGoogleAnalyticsSettings;
      },
    },
    NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
    provideGoogleAnalyticsRouter(),
  ];

  return makeEnvironmentProviders(providers);
}
