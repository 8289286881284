import { NavigationConfigService } from './config.service';
import { ENVIRONMENT_INITIALIZER, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { provideApi } from '@cue/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocaleService } from './locale.service';
import { provideRouter } from '@angular/router';
import { NavigationEnabledGuard } from './navigation-enabled.guard';
import { NotEnabledComponent } from './not-enabled/not-enabled.component';
import { configure, Localization, LOCALIZATIONS, provideLocalization } from '@cue/translate';
import { localizations } from '@assist/shared/translations';
import { ConfigService } from '@assist/shared/data';
import { TranslocoService } from '@ngneat/transloco';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePipes } from '@assist/ui/pipes';
import { provideGoogleAnalytics } from '@cue/google-analytics';
import { provideCueAnalytics, withHeadersIntecceptor } from '@cue/analytics';
import { provideNavigationSettings } from '@cue/navigation-settings';

export const appConfig = (configService: NavigationConfigService) => {
  return {
    providers: [
      {
        provide: ConfigService,
        useValue: configService,
      },
      {
        provide: NavigationConfigService,
        useValue: configService,
      },
      provideCueAnalytics(
        withHeadersIntecceptor({
          apiDomains: () => {
            const configService = inject(NavigationConfigService);
            return [configService.value.apiURL];
          },
          deviceIDHeaderName: 'deviceID',
          sessionHeaderName: 'sessionID',
        }),
      ),
      provideNavigationSettings({
        configurationFactory: () => {
          return {
            design: configService.value.design,
          };
        },
      }),
      provideGoogleAnalytics({
        keyFactory: () => {
          const configService = inject(NavigationConfigService);
          return configService.value.googleAnalyticsEnabled && configService.value.googleAnalyticsKey != null
            ? configService.value.googleAnalyticsKey
            : undefined;
        },
      }),
      providePipes({
        dateFormat: () => {
          const configService = inject(NavigationConfigService);
          if (configService.value.dateFormat != null) {
            return configService.value.dateFormat;
          } else {
            return undefined;
          }
        },
        timeFormat: () => {
          const configService = inject(NavigationConfigService);
          if (configService.value.timeFormat != null) {
            return configService.value.timeFormat;
          } else {
            return undefined;
          }
        },
      }),
      provideAnimationsAsync(),
      provideHttpClient(
        withInterceptorsFromDi(),
        withInterceptors([
          (req, next) =>
            next(
              req.url.startsWith(configService.value.apiURL)
                ? req.clone({
                    withCredentials: true,
                  })
                : req,
            ),
        ]),
      ),
      importProvidersFrom(
        DialogModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerImmediately',
        }),
      ),
      provideLocalization({
        localizations: localizations,
        translocoConfig: {
          availableLangs: configService.value.languages.filter((x) => x.activeInNavigation).map((x) => x.code),
          defaultLang: configService.value.languages.find((x) => x.default).code,
          prodMode: false,
          reRenderOnLangChange: true,
          fallbackLang: configService.value.languages.find((x) => x.default).code,
          missingHandler: {
            useFallbackTranslation: true,
          },
        },
      }),
      provideRouter([
        {
          path: 'dashboard',
          redirectTo: '',
          pathMatch: 'full',
        },
        {
          canActivate: [NavigationEnabledGuard],
          path: '',
          loadChildren: () => import('@assist/navigation/routing').then((x) => x.NavigationsRoutes),
        },
        {
          path: 'not-enabled',
          component: NotEnabledComponent,
        },
      ]),
      provideApi({
        factory: () => configService.value.apiURL,
      }),
      {
        provide: LOCALE_ID,
        deps: [LocaleService],
        useFactory: (localeService) => localeService.getLocaleId(),
      },
      {
        provide: ENVIRONMENT_INITIALIZER,
        multi: true,
        useValue() {
          const translocoService = inject(TranslocoService);
          const localizations = inject(LOCALIZATIONS) as unknown as Localization[][];
          configure(translocoService, localizations);
        },
      },
    ],
  };
};
