import { Injectable } from '@angular/core';
import { AssistFilterBM, AssistResourceBM, DesignBm, LanguageInfoBM, LocalizedString } from '@cue/api';
import { ResourceDetail } from '@assist/shared/data';
import { NavigationConfigurationCache, navigationDB } from './navigationDB';

type FileConfiguration = {
  apiURL: string;
};
type ApiConfiguration = {
  languages: LanguageInfoBM[];
  design: DesignBm;
  googleAnalyticsEnabled: boolean;
  googleAnalyticsKey?: string;
  dateFormat?: string;
  timeFormat?: string;
  enabled: boolean;
  infoSettings: {
    capacityVisible: boolean;
  };
  filters: AssistFilterBM[];
  enableReservations: boolean;
};

type Configuration = FileConfiguration & ApiConfiguration;

@Injectable()
export class NavigationConfigService {
  private CURRENT_VERSION = 8;
  private _config: Configuration;

  get value(): Configuration {
    if (!this._config)
      throw Error('Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?');
    return this._config;
  }

  getFiltersFromResource(resource: AssistResourceBM | ResourceDetail): { name: LocalizedString; value: any; dataTypeId: number }[] {
    const resourceTypeId = resource.resourceTypeId;
    const filters = this.value.filters.filter((x) => x.resourceTypeId === resourceTypeId);
    const computed = filters.map((filter) => {
      const properties = resource.properties.filter((property) => property.propertyId === filter.id);
      let value: any = null;
      switch (filter.dataTypeId) {
        case 1: {
          value = properties.length > 0 ? properties[0].valueBoolean : null;
          break;
        }
        case 2: {
          value = properties.length > 0 ? properties[0].valueNumber : null;
          break;
        }
        case 3: {
          value = properties.length > 0 ? properties[0].valueDecimal : null;
          break;
        }
        case 4: {
          value = properties.length > 0 ? (properties[0].valueText ?? '') : null;
          break;
        }
        case 5: {
          value = filter.choices
            .filter((choice) => properties.find((p) => p.valueChoiceIds.find((vch) => vch === choice.key)) != null)
            .map((x) => ({
              title: x.value,
              imageUrl: x.imageUrl,
            }));
          break;
        }
        default:
          value = null;
          break;
      }

      return {
        name: filter.name,
        value: value,
        dataTypeId: filter.dataTypeId,
      };
    });

    return computed.filter((c) => !Array.isArray(c.value) || c.value.length > 0);
  }

  getEnumerableFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail) {
    return this.getFiltersFromResource(resource).filter((x) => x.dataTypeId === 5);
  }

  getSimpleFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail) {
    return this.getFiltersFromResource(resource).filter((x) => x.dataTypeId !== 5);
  }

  prebootstrapLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      let cachedConfiguration: NavigationConfigurationCache;
      request.addEventListener('error', (e) => {
        reject('Cannot load config file.');
      });
      request.addEventListener('load', (_) => {
        if (request.status === 200) {
          try {
            const config = JSON.parse(request.responseText) as Configuration;
            const request2 = new XMLHttpRequest();
            request2.addEventListener('error', (e) => {
              reject('Cannot connect to API server, check if it is available');
            });
            request2.addEventListener('load', () => {
              if (request2.status !== 200 && request2.status !== 204) {
                reject(request2.statusText);
              }
              let response: ApiConfiguration = null;
              if (request2.status === 204) {
                response = JSON.parse(cachedConfiguration.data) as ApiConfiguration;
              }

              if (request2.status === 200) {
                try {
                  let cachedHeader = undefined;
                  try {
                    cachedHeader = request2.getResponseHeader('CachedConfigurationTimeStamp');
                  } catch {
                    cachedHeader = null;
                  }
                  if (cachedHeader) {
                    cachedConfiguration = {
                      data: request2.responseText,
                      timestamp: cachedHeader,
                      version: this.CURRENT_VERSION,
                      id: 1,
                    };

                    navigationDB.configurations.put(cachedConfiguration, 1);
                    response = JSON.parse(cachedConfiguration.data) as ApiConfiguration;
                  } else {
                    navigationDB.configurations.clear();
                    response = JSON.parse(request2.responseText);
                  }
                } catch (e) {
                  reject(e);
                }
              }
              if (response == null) {
                navigationDB.configurations.clear();
                console.error('Spatna konfigurace na serveru, /api/navigation/config nevratil zadna data!');
                reject('Navigation is not configured. Edit the settings in CUE Admin.');
              }

              const configuration = response;
              this._config = {
                ...configuration,
                apiURL: config.apiURL,
              };
              resolve(null);
            });
            let configurationUrl = config.apiURL + '/api/navigation/config';

            navigationDB.configurations.get(1).then((cache) => {
              if (cache != null && cache.version >= this.CURRENT_VERSION) {
                // Je nacachovano
                cachedConfiguration = cache;
                configurationUrl = configurationUrl + '?cachedTimestamp=' + encodeURIComponent(cache.timestamp);
              }
              request2.open('GET', configurationUrl);
              request2.send();
            });
          } catch (e) {
            reject(e);
          }
        } else {
          reject(request.statusText);
        }
      });
      request.open('GET', '/config.json');
      request.send();
    });
  }
}
