// db.ts
import Dexie, { Table } from 'dexie';

export interface NavigationConfigurationCache {
  data: string;
  timestamp: string;
  version: number;
  id: number;
}

export class NavigationDB extends Dexie {
  configurations!: Table<NavigationConfigurationCache, number>;
  constructor() {
    super('navigation');
    this.version(3).stores({
      configurations: '++id',
    });
  }
}

export const navigationDB = new NavigationDB();
