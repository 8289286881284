import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';
import { NavigationConfigService } from './app/config.service';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { forkJoin, from } from 'rxjs';
import { registerLocaleData } from '@angular/common';

if (environment.production) {
  enableProdMode();
}

const availableCodes = {
  ca: () => [import('@angular/common/locales/ca'), import('@progress/kendo-angular-intl/locales/ca/all')],
  ua: () => [import('@angular/common/locales/ru-UA'), import('@progress/kendo-angular-intl/locales/ru-UA/all')],
  fr: () => [import('@angular/common/locales/fr'), import('@progress/kendo-angular-intl/locales/fr/all')],
  no: () => [import('@angular/common/locales/nb'), import('@progress/kendo-angular-intl/locales/nb/all')],
  nb: () => [import('@angular/common/locales/nb'), import('@progress/kendo-angular-intl/locales/nb/all')],
  en: () => [import('@angular/common/locales/en'), import('@progress/kendo-angular-intl/locales/en/all')],
  de: () => [import('@angular/common/locales/de'), import('@progress/kendo-angular-intl/locales/de/all')],
  cs: () => [import('@angular/common/locales/cs'), import('@progress/kendo-angular-intl/locales/cs/all')],
  es: () => [import('@angular/common/locales/es'), import('@progress/kendo-angular-intl/locales/es/all')],
};

const defaultImport = () => [import('@angular/common/locales/en'), import('@progress/kendo-angular-intl/locales/en/all')];

function getLocaleId(): [Promise<any>, Promise<any>] {
  const userLang = navigator.language || (navigator as any).userLanguage;
  const code = userLang.split('-')[0];
  const foundCode = availableCodes[code];
  const returnCode = foundCode ? foundCode() : defaultImport();
  return returnCode;
}

const navigationConfigService = new NavigationConfigService();

const bootstrap = () => bootstrapApplication(AppComponent, appConfig(navigationConfigService));

const promises = getLocaleId();
forkJoin(promises.map((x) => from(x))).subscribe((lang) => {
  registerLocaleData(lang[0].default);

  navigationConfigService.prebootstrapLoad().then(
    (_) => {
      setTimeout(() => {
        bootstrap().catch((err) => console.log(err));
      });
    },
    (error) => {
      window.alert(error);
    },
  );
});
