import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { CommonModule } from '@angular/common';
import { IconComponent, cueClose } from '@cue/ui/icons';

@Component({
  selector: 'cue-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class ModalHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() emitClose = false;
  @Input() tabs: string[];
  @Input() tabsVisible = false;
  @Output() tabClick: EventEmitter<number> = new EventEmitter();
  @Output() headerClose: EventEmitter<any> = new EventEmitter<any>();

  selectedTab = 0;
  cueClose = cueClose;

  constructor(public modal: ModalComponent) {}

  ngOnInit() {}

  xClose() {
    if (this.emitClose) {
      this.headerClose.emit();
    } else {
      this.modal.onClose();
    }
  }
  changeTab(i: number) {
    this.selectedTab = i;
  }
}
