import * as i0 from '@angular/core';
import { Directive, Input, InjectionToken, inject, isDevMode, Injectable, ElementRef, APP_INITIALIZER, APP_BOOTSTRAP_LISTENER, NgModule, makeEnvironmentProviders } from '@angular/core';
import { fromEvent, filter, skip } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
class GaEventCategoryDirective {
  static {
    this.ɵfac = function GaEventCategoryDirective_Factory(t) {
      return new (t || GaEventCategoryDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GaEventCategoryDirective,
      selectors: [["", "gaEvent", "", "gaCategory", ""], ["", "gaCategory", ""]],
      inputs: {
        gaCategory: "gaCategory"
      },
      exportAs: ["gaCategory"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaEventCategoryDirective, [{
    type: Directive,
    args: [{
      selector: `
        [gaEvent][gaCategory],
        [gaCategory]
    `,
      exportAs: 'gaCategory',
      standalone: true
    }]
  }], null, {
    gaCategory: [{
      type: Input
    }]
  });
})();

/**
 * Provide an Injection Token for global settings.
 */
const NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN = new InjectionToken('ngx-google-analytics-settings', {
  factory: () => ({
    ga4TagId: '',
    enableTracing: false
  })
});

/**
 * Provide DOM Window reference.
 */
const NGX_WINDOW = new InjectionToken('ngx-window', {
  providedIn: 'root',
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window is not available');
    }
    return defaultView;
  }
});

/**
 * Check if there is some global function called gtag on Window object, or create an empty function that doesn't break code...
 */
function getDataLayerFn(window) {
  return window ? window['dataLayer'] = window['dataLayer'] || [] : null;
}
/**
 * Provides an injection token to access Google Analytics DataLayer Collection
 */
const NGX_DATA_LAYER = new InjectionToken('ngx-data-layer', {
  providedIn: 'root',
  factory: () => getDataLayerFn(inject(NGX_WINDOW))
});

/**
 * Check if there is some global function called gtag on Window object, or create an empty function that doesn't break code...
 */
function getGtagFn(window, dataLayer) {
  return window ? window['gtag'] = window['gtag'] || function () {
    // IMPORTANT: rest param syntax (...args) cannot be used here since "gtag" push implementation requires
    // "callee" information which is not available in normal array
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  } : null;
}
/**
 * Provides an injection token to access Google Analytics Gtag Function
 */
const NGX_GTAG_FN = new InjectionToken('ngx-gtag-fn', {
  providedIn: 'root',
  factory: () => getGtagFn(inject(NGX_WINDOW), inject(NGX_DATA_LAYER))
});
class GoogleAnalyticsService {
  constructor() {
    this.settings = inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN);
    this._document = inject(DOCUMENT);
    this._gtag = inject(NGX_GTAG_FN);
    /**
     * Call native GA Tag
     */
    this.gtag = (...args) => {
      try {
        this._gtag(...args.filter(x => x !== undefined));
      } catch (err) {
        this.throw(err);
      }
    };
  }
  get document() {
    return this._document;
  }
  /**
   * Send an event trigger to GA. This is the same as:
   * ```js
   * gtag('event', 'video_auto_play_start', {
   *   'event_label': 'My promotional video',
   *   'event_category': 'video_auto_play'
   * });
   * ```
   *
   * @param action 'video_auto_play_start'
   * @param options event options (category, label, value, interaction, [custom dimensions] options)
   */
  event(action, options) {
    try {
      const opt = new Map();
      if (options?.category !== undefined) {
        opt.set('event_category', options.category);
      }
      if (options?.label !== undefined) {
        opt.set('event_label', options.label);
      }
      if (options?.value !== undefined) {
        opt.set('value', options.value);
      }
      if (options?.interaction !== undefined) {
        opt.set('interaction', options.interaction);
      }
      if (options?.options !== undefined) {
        Object.entries(options.options).map(([key, value]) => opt.set(key, value));
      }
      const params = this.toKeyValue(opt);
      if (params) {
        this.gtag('event', action, params);
      } else {
        this.gtag('event', action);
      }
    } catch (error) {
      this.throw(error);
    }
  }
  /**
   * Send a page view event. This is the same as:
   *
   * ```js
   * gtag('config', 'GA_TRACKING_ID', {
   *   'page_title' : 'Homepage',
   *   'page_path': '/home'
   * });
   * ```
   *
   * The tracking ID is injected automatically by Inject Token NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN
   *
   * @param path /home
   * @param options pageView options (title, location, [custom dimensions] options)
   */
  pageView(path, options) {
    try {
      const opt = new Map([['page_path', path]]);
      if (options?.title !== undefined) {
        opt.set('page_title', options.title);
      }
      if (options?.location !== undefined || this.document) {
        opt.set('page_location', options?.location ?? this.document.location.href);
      }
      if (options?.options !== undefined) {
        Object.entries(options.options).map(([key, value]) => opt.set(key, value));
      }
      this.gtag('event', 'page_view', this.toKeyValue(opt));
    } catch (error) {
      this.throw(error);
    }
  }
  /**
   * Send an event to report a App Page View. This is the same as:
   *
   * ```js
   * gtag('event', 'screen_view', {
   *   'app_name': 'myAppName',
   *   'screen_name' : 'Home'
   * });
   *
   * ```
   *
   * @param screen 'screen_name'
   * @param appName 'app_name'
   * @param options appView options (appId, appVersion, installerId)
   */
  appView(screen, appName, options) {
    try {
      const opt = new Map([['screen_name', screen], ['app_name', appName]]);
      if (options?.appId !== undefined) {
        opt.set('app_id', options.appId);
      }
      if (options?.appVersion !== undefined) {
        opt.set('app_version', options.appVersion);
      }
      if (options?.installerId !== undefined) {
        opt.set('app_installer_id', options.installerId);
      }
      this.gtag('event', 'screen_view', this.toKeyValue(opt));
    } catch (error) {
      this.throw(error);
    }
  }
  // noinspection SpellCheckingInspection
  /**
   * Defines persistent values on GoogleAnalytics
   *
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values
   *
   * ```js
   * gtag('set', {
   *   'currency': 'USD',
   *   'country': 'US'
   * });
   * ```
   */
  set(options) {
    try {
      this._gtag('set', options);
    } catch (err) {
      this.throw(err);
    }
  }
  /**
   * Send an event to GA to report an application error. This is the same as:
   *
   * ```js
   * gtag('event', 'exception', {
   *   'description': 'error_description',
   *   'fatal': false   // set to true if the error is fatal
   * });
   * ```
   *
   * @param description 'error_description'
   * @param fatal set to true if the error is fatal
   */
  exception(description, fatal) {
    try {
      const opt = new Map();
      if (description !== undefined) {
        opt.set('description', description);
      }
      if (fatal !== undefined) {
        opt.set('fatal', fatal);
      }
      const params = this.toKeyValue(opt);
      if (params) {
        this.gtag('event', 'app_exception', params);
      } else {
        this.gtag('event', 'app_exception');
      }
    } catch (error) {
      this.throw(error);
    }
  }
  throw(err) {
    if ((this.settings.enableTracing || isDevMode()) && console && console.error) {
      console.error(err);
    }
  }
  toKeyValue(map) {
    if (map.size)
      // > 0
      return Object.fromEntries(map);
  }
  static {
    this.ɵfac = function GoogleAnalyticsService_Factory(t) {
      return new (t || GoogleAnalyticsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleAnalyticsService,
      factory: GoogleAnalyticsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleAnalyticsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class GaEventDirective {
  constructor() {
    this.gaCategoryDirective = inject(GaEventCategoryDirective, {
      optional: true
    });
    this.gaService = inject(GoogleAnalyticsService);
    this.settings = inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN);
    this.el = inject(ElementRef);
    this.gaBind = 'click';
  }
  set gaBind(gaBind) {
    if (this.bindSubscription) {
      this.bindSubscription.unsubscribe();
    }
    this._gaBind = gaBind;
    this.bindSubscription = fromEvent(this.el.nativeElement, gaBind).subscribe(() => this.trigger());
  }
  get gaBind() {
    return this._gaBind;
  }
  ngOnDestroy() {
    if (this.bindSubscription) {
      this.bindSubscription.unsubscribe();
    }
  }
  trigger() {
    try {
      if (!this.gaAction && !this.gaEvent) {
        throw new Error('You must provide a gaAction attribute to identify this event.');
      }
      this.gaService.event(this.gaAction || this.gaEvent, {
        category: this.gaCategoryDirective?.gaCategory,
        label: this.gaLabel || this.label,
        value: this.gaValue,
        interaction: this.gaInteraction
      });
    } catch (err) {
      this.throw(err);
    }
  }
  throw(err) {
    if ((isDevMode() || this.settings.enableTracing) && console && console.warn) {
      console.warn(err);
    }
  }
  static {
    this.ɵfac = function GaEventDirective_Factory(t) {
      return new (t || GaEventDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GaEventDirective,
      selectors: [["", "gaEvent", ""]],
      inputs: {
        gaAction: "gaAction",
        gaLabel: "gaLabel",
        label: "label",
        gaValue: "gaValue",
        gaInteraction: "gaInteraction",
        gaEvent: "gaEvent",
        gaBind: "gaBind"
      },
      exportAs: ["gaEvent"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaEventDirective, [{
    type: Directive,
    args: [{
      selector: `[gaEvent]`,
      exportAs: 'gaEvent',
      standalone: true
    }]
  }], function () {
    return [];
  }, {
    gaAction: [{
      type: Input
    }],
    gaLabel: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    gaValue: [{
      type: Input
    }],
    gaInteraction: [{
      type: Input
    }],
    gaEvent: [{
      type: Input
    }],
    gaBind: [{
      type: Input
    }]
  });
})();
class GaEventFormInputDirective {
  constructor() {
    this.gaEvent = inject(GaEventDirective, {
      optional: true,
      host: true
    });
    this.gaBind = 'focus';
  }
  set gaBind(bind) {
    if (this.gaEvent) {
      this.gaEvent.gaBind = bind;
    }
  }
  static {
    this.ɵfac = function GaEventFormInputDirective_Factory(t) {
      return new (t || GaEventFormInputDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GaEventFormInputDirective,
      selectors: [["input", "gaEvent", ""], ["select", "gaEvent", ""], ["textarea", "gaEvent", ""]],
      inputs: {
        gaBind: "gaBind"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaEventFormInputDirective, [{
    type: Directive,
    args: [{
      selector: `
        input[gaEvent],
        select[gaEvent],
        textarea[gaEvent]
    `,
      standalone: true
    }]
  }], function () {
    return [];
  }, {
    gaBind: [{
      type: Input
    }]
  });
})();

// noinspection JSUnusedGlobalSymbols
var GaActionEnum;
(function (GaActionEnum) {
  GaActionEnum["ADD_PAYMENT_INFO"] = "add_payment_info";
  GaActionEnum["ADD_TO_CART"] = "add_to_cart";
  GaActionEnum["ADD_TO_WISHLIST"] = "add_to_wishlist";
  GaActionEnum["BEGIN_CHECKOUT"] = "begin_checkout";
  GaActionEnum["CHECKOUT_PROGRESS"] = "checkout_progress";
  GaActionEnum["GENERATE_LEAD"] = "generate_lead";
  GaActionEnum["LOGIN"] = "login";
  GaActionEnum["PURCHASE"] = "purchase";
  GaActionEnum["REFUND"] = "refund";
  GaActionEnum["REMOVE_FROM_CART"] = "remove_from_cart";
  GaActionEnum["SEARCH"] = "search";
  GaActionEnum["SELECT_CONTENT"] = "select_content";
  GaActionEnum["SET_CHECKOUT_OPTION"] = "set_checkout_option";
  GaActionEnum["SHARE"] = "share";
  GaActionEnum["SIGN_UP"] = "sign_up";
  GaActionEnum["VIEW_ITEM"] = "view_item";
  GaActionEnum["VIEW_ITEM_LIST"] = "view_item_list";
  GaActionEnum["VIEW_PROMOTION"] = "view_promotion";
  GaActionEnum["VIEW_SEARCH_RESULT"] = "view_search_results";
})(GaActionEnum || (GaActionEnum = {}));

/**
 * Provide a DI Configuration to attach GA Initialization at Angular Startup Cycle.
 */
const NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: GoogleAnalyticsInitializer,
  deps: [NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN, DOCUMENT]
};
/**
 * Create a script element on DOM and link it to Google Analytics tracking code URI.
 * After that, execute exactly same init process as tracking snippet code.
 */
function GoogleAnalyticsInitializer(settings, gtag, document) {
  return async () => {
    if (!settings.ga4TagId) {
      if (!isDevMode()) {
        console.error('Empty tracking code for Google Analytics. Make sure to provide one when initializing NgxGoogleAnalyticsModule.');
      }
      return;
    }
    if (!gtag) {
      if (!isDevMode()) {
        console.error('Couldn\'t create or read gtag() fn. Make sure this module is running on a Browser w/ access to Window interface.');
      }
      return;
    }
    if (!document) {
      if (!isDevMode()) {
        console.error('Couldn\'t to access Document interface. Make sure this module is running on a Browser w/ access to Document interface.');
      }
    }
    // Set default ga.js uri
    settings.uri = settings.uri || `https://www.googletagmanager.com/gtag/js?id=${settings.ga4TagId}`;
    // these commands should run first!
    settings.initCommands = settings?.initCommands ?? [];
    // assert config command
    if (!settings.initCommands.find(x => x.command === 'config')) {
      settings.initCommands.unshift({
        command: 'config',
        values: [settings.ga4TagId]
      });
    }
    // assert js command
    if (!settings.initCommands.find(x => x.command === 'js')) {
      settings.initCommands.unshift({
        command: 'js',
        values: [new Date()]
      });
    }
    for (const command of settings.initCommands) {
      gtag(command.command, ...command.values);
    }
    const s = document.createElement('script');
    s.async = true;
    s.src = settings.uri;
    if (settings.nonce) {
      s.setAttribute('nonce', settings.nonce);
    }
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  };
}

/**
 * Provide an Injection Token for global settings.
 */
const NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN = new InjectionToken('ngx-google-analytics-routing-settings', {
  factory: () => ({})
});

/**
 * Provide a DI Configuration to attach GA Trigger to Router Events at Angular Startup Cycle.
 */
const NGX_GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: GoogleAnalyticsRouterInitializer,
  deps: [NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN, GoogleAnalyticsService]
};
/**
 * Attach a listener to `NavigationEnd` Router event. So, every time Router finish the page resolution it should call `NavigationEnd` event.
 * We assume that NavigationEnd is the final page resolution and call GA `page_view` command.
 *
 * To avoid double binds, we also destroy the subscription when de Bootstrap Component is destroyed. But, we don't know for sure
 * that this strategy does not cause double bind on multiple bootstrap components.
 *
 * We are using the component's injector reference to resolve Router, so I hope there is no problem with double binding.
 *
 * If you have this problem, I encourage not Use NgxGoogleAnalyticsRouterModule and attach the listener on AppComponent initialization.
 */
function GoogleAnalyticsRouterInitializer(settings, gaService) {
  return c => {
    const router = c.injector.get(Router);
    const {
      include = [],
      exclude = []
    } = settings ?? {};
    const includeRules = normalizePathRules(include);
    const excludeRules = normalizePathRules(exclude);
    const subs = router.events.pipe(filter(event => event instanceof NavigationEnd), skip(1),
    // Prevent double views on the first trigger (because GA Already send one ping on setup)
    filter(event => includeRules.length > 0 ? includeRules.some(rule => rule.test(event.urlAfterRedirects)) : true), filter(event => excludeRules.length > 0 ? !excludeRules.some(rule => rule.test(event.urlAfterRedirects)) : true)).subscribe(event => gaService.pageView(event.urlAfterRedirects, undefined));
    // Cleanup
    c.onDestroy(() => subs.unsubscribe());
  };
}
/** Converts all path rules from string to Regex instances */
function normalizePathRules(rules) {
  return rules.map(rule => rule instanceof RegExp ? rule : new RegExp(`^${rule.replace('*', '.*')}$`, 'i'));
}

// noinspection JSUnusedGlobalSymbols

const COMPONENTS = [GaEventDirective, GaEventCategoryDirective, GaEventFormInputDirective];
class NgxGoogleAnalyticsModule {
  static {
    this.ɵfac = function NgxGoogleAnalyticsModule_Factory(t) {
      return new (t || NgxGoogleAnalyticsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxGoogleAnalyticsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGoogleAnalyticsModule, [{
    type: NgModule,
    args: [{
      imports: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

// noinspection JSUnusedGlobalSymbols
function provideGoogleAnalyticsRouter(settings) {
  return makeEnvironmentProviders([NGX_GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER, {
    provide: NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN,
    useValue: settings ?? {}
  }]);
}
function provideGoogleAnalytics(ga4TagId, options) {
  return makeEnvironmentProviders([{
    provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
    useValue: {
      ga4TagId,
      ...options
    }
  }, NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER]);
}

/*
 * Public API Surface of ngx-google-analytics
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GaActionEnum, GaEventCategoryDirective, GaEventDirective, GaEventFormInputDirective, GoogleAnalyticsInitializer, GoogleAnalyticsRouterInitializer, GoogleAnalyticsService, NGX_DATA_LAYER, NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER, NGX_GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER, NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN, NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN, NGX_WINDOW, NgxGoogleAnalyticsModule, getDataLayerFn, getGtagFn, provideGoogleAnalytics, provideGoogleAnalyticsRouter };
